
import { computed, defineComponent, inject, reactive, ref } from 'vue'
import axios from '@/http'
import { notifyInstance, sales } from '@/type'
import { useStore } from 'vuex'
import moment from 'moment'

moment.locale('zh-tw')

interface topItem {
  name: string
  specification?: string
  number: number
  price: number
}

export default defineComponent({
  setup() {
    const notify = inject('notify') as notifyInstance

    const store = useStore()

    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)

    const sales = reactive({
      storeSale: 0,
      storeOnline: 0,
      storePre: 0,
      storePreOnline: 0,
      storePartialPickup: 0,
      partialPickupQuantity: 0,
      voidedOrder: 0,
      voidedQuantity: 0,
      voidedOnlineOrder: 0,
      voidedOnlineOrderQuantity: 0,
      voidedPreOrder: 0,
      voidedPreOrderQuantity: 0,
      voidedPreOnlineOrder: 0,
      voidedPreOnlineOrderQuantity: 0,
      quantity: 0,
      onlineQuantity: 0,
      preQuantity: 0,
      preOnlineQuantity: 0,
      totalTimes: 0,
      onSaleAmount: 0,
      voidedPartialPickup: 0,
      voidedPartialPickupQuantity: 0
    } as sales)
    const products = ref(new Array<topItem>())

    function formatNumber(number: number) {
      return number.toLocaleString('en-US')
    }

    function init() {
      axios
        .get(`/saleReport/${storeId.value}/statistics/daily`)
        .then((res) => {
          const data = res.data
          Object.assign(sales, data)
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `載入失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })

      axios
        .get(`/topSaleReport/${storeId.value}/statistics`, {
          params: {
            startTime: moment().startOf('day').format('YYYY-MM-DDTHH:mm'),
            endTime: moment().endOf('day').format('YYYY-MM-DDTHH:mm'),
            number: 10
          }
        })
        .then((res) => {
          const data = res.data
          Object.assign(products.value, data.products)
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `載入失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })
    }

    function getProductStandardName(product: any) {
      if (product.standardNameMain.length > 0) {
        if (product.standardNameSub.length > 0) {
          return `(${product.standardNameMain}-${product.standardNameSub})`
        }
        return `(${product.standardNameMain})`
      }
      return ''
    }

    function getNumber(number: any) {
      if (typeof number !== 'number' || isNaN(number)) {
        return 0
      }
      return number
    }

    init()

    return {
      formatNumber,
      sales,
      products,
      getProductStandardName,
      getNumber
    }
  }
})
