
import { computed, defineComponent, inject, reactive, ref, watch } from 'vue'
import helper from '../../helper'
import Timer from '@/views/components/timer.vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { permissionInstance } from '@/type'

interface IMenu {
  title: string
  isOpen: boolean
  routeName?: string
  subLists?: {
    title: string
    routeName: string
    hasDropLine: boolean
  }[]
  activeRoutes: string[]
}

export default defineComponent({
  components: { Timer },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const env = computed(() => process.env.NODE_ENV)
    const nowStore = computed(() => store.getters.nowStore)
    const stores = computed(() => store.getters.stores)
    const user = reactive(helper.getUser())
    const menus = reactive<IMenu[]>([])

    watch(nowStore, (newValue) => {
      if (newValue.id > 0) {
        menus.splice(0, menus.length)
        menus.push({
          title: '營業報表',
          isOpen: false,
          subLists: [
            {
              title: '目前現況',
              routeName: 'index',
              hasDropLine: false
            },
            {
              title: '營業分析',
              routeName: 'analysisPerformance',
              hasDropLine: false
            },
            {
              title: '商品銷售',
              routeName: 'productSales',
              hasDropLine: false
            },
            {
              title: '寄存銷售',
              routeName: 'partialpickup',
              hasDropLine: false
            },
            {
              title: '庫存警示',
              routeName: 'oversold',
              hasDropLine: true
            },
            {
              title: '小結 | 關帳',
              routeName: 'handover',
              hasDropLine: false
            },
            {
              title: '訂金紀錄',
              routeName: 'pre',
              hasDropLine: false
            },
            {
              title: '結帳紀錄',
              routeName: 'succeed',
              hasDropLine: false
            },
            {
              title: '發票紀錄',
              routeName: 'invoice',
              hasDropLine: false
            },
            {
              title: '作廢紀錄',
              routeName: 'void',
              hasDropLine: false
            },
            {
              title: '臨時備用現金管理',
              routeName: 'temporaryReserve',
              hasDropLine: true
            },
            {
              title: '打卡紀錄',
              routeName: 'clockRecords',
              hasDropLine: false
            },
            {
              title: '權限紀錄',
              routeName: 'permission',
              hasDropLine: false
            },
            {
              title: '員工業績列表',
              routeName: 'performance',
              hasDropLine: false
            }
          ],
          activeRoutes: [
            'orderCurrent',
            'index',
            'handover',
            'analysisPerformance',
            'analysisDiscount',
            'analysisRevenue',
            'analysisStoreOrder',
            'analysisOnlineOrder',
            'productSales',
            'pre',
            'succeed',
            'void',
            'temporaryReserve',
            'permission',
            'performance',
            'clockRecords',
            'clockPersonal',
            'partialpickupRecord',
            'partialpickup',
            'oversold',
            'invoice'
          ]
        })
        if (isAdmin() || isStoreAdmin()) {
          // 主帳號才有管理POS後台權限
          const authSubLists = [
            {
              title: 'APP門市',
              routeName: 'authStore',
              hasDropLine: false
            }
          ]

          if (isAdmin()) {
            authSubLists.push({
              title: 'POS後台',
              routeName: 'authPOS',
              hasDropLine: false
            })
          }

          menus.push(
            {
              title: '帳號與權限',
              isOpen: false,
              subLists: authSubLists,
              activeRoutes: [
                'authPOS',
                'authStoreCreate',
                'authStore',
                'authPermission',
                'authGroup',
                'authGroupCreate',
                'authEmployee',
                'authEmployeeCreate',
                'authResigned'
              ]
            },
            {
              title: '系統設置',
              isOpen: false,
              subLists: [
                {
                  title: '電子發票管理',
                  routeName: 'systemReceipt',
                  hasDropLine: false
                },
                {
                  title: '支付管理',
                  routeName: 'systemPayment',
                  hasDropLine: false
                },
                {
                  title: '出單機設定',
                  routeName: 'systemPrinter',
                  hasDropLine: false
                }
              ],
              activeRoutes: ['systemReceipt', 'systemPayment', 'systemPrinter']
            },
            {
              title: '門市管理',
              isOpen: false,
              subLists: [
                {
                  title: '門市資訊設定',
                  routeName: 'storeInfo',
                  hasDropLine: false
                },
                {
                  title: '門市行銷設定',
                  routeName: 'storeMarketing_memberLogin',
                  hasDropLine: false
                },
                {
                  title: '門市商品設定',
                  routeName: 'storeProduct_list',
                  hasDropLine: false
                }
              ],
              activeRoutes: [
                'storeInfo',
                'storeMarketing_memberLogin',
                'storeProduct_list'
              ]
            }
          )
        }
      }
    })

    const isMemberNavOpen = ref(false)

    function logout() {
      helper.logout()
    }

    function changeNowStore(event: any) {
      const storeObj = stores.value.find(
        (store: any) => parseInt(store.id) === parseInt(event.target.value)
      )
      store.dispatch('changeNowStore', storeObj).then(() => {
        router.push({
          name: 'refresh'
        })
      })
    }

    function isAdmin() {
      return helper.isAdmin()
    }

    function isStoreAdmin() {
      return helper.isStoreAdmin()
    }

    return {
      env,
      nowStore,
      stores,
      user,
      logout,
      changeNowStore,
      route,
      menus,
      isMemberNavOpen,
      isAdmin,
      isStoreAdmin
    }
  }
})
