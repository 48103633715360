
import { emitter } from '@/plugins/events'
import { defineComponent, ref, onMounted, computed } from 'vue'

export default defineComponent({
  emits: ['init'],
  setup(props, { emit }) {
    const show = ref(false)
    const type = ref('success')
    const message = ref('')
    const closeAble = ref(false)
    const time = ref(0)

    const classMap = new Map()
    classMap.set('success', {
      class: 'is-success',
      iconClass: 'icon-success c-icon--gn50'
    })
    classMap.set('error', {
      class: 'is-error',
      iconClass: 'icon-error c-icon--rd60'
    })
    const classType = computed(() => classMap.get(type.value))

    function notify(options: any) {
      const notificationOptions = options.notificationOptions
      const timeout = options.timeout

      type.value = notificationOptions.classType ?? 'success'
      message.value = notificationOptions.message
      closeAble.value = notificationOptions.closeAble ?? false
      time.value = timeout ?? 0

      const hasInnerNotify = notificationOptions.inner ?? false
      emit('init', hasInnerNotify)
    }

    function close() {
      time.value = 0
    }

    onMounted(() => {
      emitter.on('addNotify', notify)
      emitter.on('closeNotify', close)
      show.value = true
      setTimeOut()
    })

    function setTimeOut() {
      setInterval(() => {
        if (time.value > 0) {
          time.value -= 100
        }
      }, 100)
    }

    return {
      message,
      closeAble,
      time,
      close,
      show,
      classType
    }
  }
})
