import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "l-container" }
const _hoisted_2 = { class: "l-main" }
const _hoisted_3 = {
  class: "l-main__wrap",
  "data-main-wrap": ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal = _resolveComponent("modal")!
  const _component_pending = _resolveComponent("pending")!
  const _component_app_nav = _resolveComponent("app-nav")!
  const _component_notification = _resolveComponent("notification")!
  const _component_common = _resolveComponent("common")!
  const _component_permission = _resolveComponent("permission")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_modal),
    _createVNode(_component_pending),
    _createVNode(_component_app_nav),
    _createElementVNode("main", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("div", null, [
          _createVNode(_component_notification, {
            class: "l-main__status",
            onInit: _ctx.notifyInit
          }, null, 8, ["onInit"])
        ], 512), [
          [_vShow, _ctx.notifyVisible]
        ]),
        (_ctx.route.meta.permission && _ctx.noPermission(_ctx.route.meta.permission))
          ? (_openBlock(), _createBlock(_component_common, { key: 0 }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_ctx.route.meta.permission)
                ? (_openBlock(), _createBlock(_component_permission, {
                    key: 0,
                    actions: _ctx.getActions()
                  }, null, 8, ["actions"]))
                : _createCommentVNode("", true),
              _createVNode(_component_router_view)
            ], 64))
      ])
    ])
  ]))
}