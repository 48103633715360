
import { computed, defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'
import modal from '@/views/components/modal.vue'
import notification from '@/views/components/notification.vue'
import pending from '@/views/components/pending.vue'
import permission from '@/views/components/reportPermission.vue'

import AppNav from './nav.vue'
import { useStore } from 'vuex'
import helper from '@/helper'
import Common from '@/views/status/common.vue'
export default defineComponent({
  components: {
    Common,
    'app-nav': AppNav,
    notification,
    modal,
    pending,
    permission
  },
  setup() {
    const route = useRoute()
    const store = useStore()

    const notifyVisible = ref(false)

    const permissionLists = computed(() => store.getters.permissionLists)
    function getActions() {
      if (route.meta.permission) {
        const keys = (route.meta.permission as string).split('.')
        const moduleKey = keys[0]
        const groupKey = keys[1]

        const matchedModule = permissionLists.value.find(
          (module: { key: any }) => module.key === moduleKey
        )

        if (matchedModule) {
          const matchedGroup = matchedModule.groups.find(
            (group: { key: any }) => group.key === groupKey
          )
          if (matchedGroup) {
            return matchedGroup.actions.map((actionItem: any) => ({
              permissionKey: `${moduleKey}.${groupKey}.${actionItem.key}`,
              name: actionItem.name
            }))
          }
        }
      }
      return []
    }

    function noPermission(group: any) {
      return (
        !helper.isAdmin() &&
        !helper.isStoreAdmin() &&
        !helper.hasReadPermission(group)
      )
    }

    function notifyInit(hasInner: boolean) {
      notifyVisible.value = !hasInner
    }

    return {
      route,
      getActions,
      noPermission,
      notifyInit,
      notifyVisible
    }
  }
})
