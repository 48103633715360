
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'Modal',
  props: ['originFullPath', 'originRouteName'],
  setup(props) {
    const store = useStore()
    const router = useRouter()

    const show = ref(false)
    const checkedStore = ref(null)
    const zIndex = ref(1032)
    const maskZIndex = ref(1031)
    const storeRequired = ref(true)

    const stores = computed(() => store.getters.stores)
    const storeQuantity = computed(() => store.getters.storeQuantity)
    const usedStoreQuantity = computed(() => store.getters.usedStoreQuantity)
    const alertPasswordChange = computed(() => store.getters.alertPasswordChange)

    function go() {
      if (checkedStore.value !== null) {
        store.dispatch('changeNowStore', checkedStore.value).then(() => {
          const fullPath = props.originFullPath ?? ''
          const routeName = props.originRouteName ?? ''
          if (!['nowStoreSelector', ''].includes(routeName.toString())) {
            router.replace(fullPath)
          } else {
            router.push({
              name: 'index'
            })
          }
        })
      }
    }

    function createNewStore() {
      router.push({
        name: 'authStoreCreate'
      })
    }

    onMounted(() => {
      show.value = true
    })

    return {
      checkedStore,
      zIndex,
      maskZIndex,
      storeRequired,
      stores,
      storeQuantity,
      usedStoreQuantity,
      go,
      createNewStore,
      show,
      alertPasswordChange
    }
  }
})
