
import { emitter } from '@/plugins/events'
import { defineComponent, ref, onMounted, computed } from 'vue'
export default defineComponent({
  setup() {
    const show = ref(false)

    function start() {
      show.value = true
    }

    function done() {
      show.value = false
    }

    onMounted(() => {
      emitter.on('startPending', start)
      emitter.on('donePending', done)
    })

    return {
      show
    }
  }
})
