
import {
  defineComponent,
  ref,
  computed,
  watch,
  onBeforeUnmount,
  inject
} from 'vue'
import { modalInstance } from '@/type'

import helper from '@/helper'
import { useStore } from 'vuex'

export default defineComponent({
  setup() {
    const store = useStore()
    const modal = inject('modal') as modalInstance

    const logoutTime = computed(() => store.getters.logoutTime)
    const timeLeft = ref((logoutTime.value - Date.now()) / 1000)
    const hourSeconds = 60 * 60
    const minuteSeconds = 60

    watch(timeLeft, (newValue) => {
      const newTimeLeft = Math.floor(newValue)

      if (newTimeLeft <= 0) {
        helper.logout()
      }

      // 倒數5分鐘，跳出彈窗提示
      if (newTimeLeft <= 5 * 60) {
        modal({
          content:
            '提醒您，再過5分鐘系統會將您自動登出，請問是否需要延長登出時間',
          confirmBtnText: '確定',
          classType: 'alert',
          cancelBtn: false,
          confirmBtnClick() {
            store.commit('setLogoutTime')
            // 打ajax延長後端時間
            store.dispatch('initSetup')
          }
        }).show()
      }
    })

    const countdownTmpl = computed(() => {
      const minutes = Math.floor((timeLeft.value % hourSeconds) / minuteSeconds)
      const seconds = Math.floor(timeLeft.value % minuteSeconds)
      return `${minutes}:${seconds.toString().padStart(2, '0')}`
    })

    const timeMinus = () => {
      timeLeft.value = (logoutTime.value - Date.now()) / 1000
    }

    const newTimer = setInterval(timeMinus, 500)

    timeMinus()

    onBeforeUnmount(() => {
      clearInterval(newTimer)
    })

    return { countdownTmpl }
  }
})
